import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base } from '@app/models/base';
import { tbl_fahrtbericht } from '@app/models/tbl_fahrtbericht';
import { tbl_fahrtbericht_abschnitt } from '@app/models/tbl_fahrtbericht_abschnitt';
import { tbl_fahrtbericht_abschnitt_typ } from '@app/models/tbl_fahrtbericht_abschnitt_typ';
import { Chassis, Construction, Fraction, Lifter, StaffMember, tbl_fahrzeug, Tour, VehicleFile } from '@app/models/tbl_fahrzeug';
import { tbl_firma } from '@app/models/tbl_firma';
import { tbl_gps } from '@app/models/tbl_gps';
import { tbl_objekt } from '@app/models/tbl_objekt';
import { tbl_standort } from '@app/models/tbl_standort';
import { tbl_volumen } from '@app/models/tbl_volumen';
import { environment } from '@environments/environment';
import { Area, AreaPoint } from '@models/area';
import { Client } from '@models/bhv';
import { Collection } from '@models/collection';
import { GfStammdaten } from '@models/gfstammdaten';
import { Track } from '@models/reversingcadastral';
import { tbl_gefaess } from '@models/tbl_gefaess';
import { tbl_leerung_barcode_status } from '@models/tbl_leerung_barcode_status';
import { AccountService } from '@services/account.service';
import { BaumschnittBerechtigung } from '@app/models/baumschnittBerechtigung';

@Injectable()
export class CRUDService {
	constructor(
		private accountService: AccountService,
		private http: HttpClient
	) { }

	/**
	 * Wieviele Einträge sollen maximal auf einmal geladen werden
	 * 
	 * @returns Anzahl maximal zu ladende Einträge
	 */
	getMaxEntries(): number {
		return +localStorage.getItem('maxEntries');
	}

	// #region Stammdaten

	checkPersistence(url: string, date: Date): Promise<boolean> {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/${url}/CheckPersistence`, date, options).toPromise());
		});
	}

	createEntry(url: string, entry: Base): Promise<any> {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/${url}/create`, entry, options).toPromise());
		});
	}

	deleteEntry(url: string, id: number): Promise<any> {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/${url}/delete/` + id, options).toPromise());
		});
	}

	editEntry(url: string, entry: Base): Promise<any> {
		if( entry && entry.ds_mandant_id_ex ) {
			/*
			 * Erläuterung:
			 * ds_mandant_id ist ein UInt64, wird beim deserialisieren von Angular leider auf 53-Bit precision beschnitten,
			 * und dann beim Editieren verfälscht zurückgeschrieben.
			 * ds_mandant_id_ex ist ein Getter im C# Modell, welches den Wert von ds_mandant_id als string liefert, somit unverfälscht, aber in falschem Format.
			 * Aber, der Trick: ds_mandant_id_ex hat ebenfalls einen Setter im C# Modell, welcher dann einen Stringwert nimmt und nach UInt64 konvertiert, diesen dann 
			 * in ds_mandant_id schreibt. Und da dieser Setter einen String bekommt, der beim serialisieren/deserialisieren erhalten bleibt, und dann auf Serverseite die 
			 * Konvertierung nach UInt64 vornimmt, wird über den String der korrekte Wert ins Frontend getragen,
			 * und wieder über den String der korrekte Wert vom Frontend in das Backend übertragen und der verfälschte UInt64 aus ds_mandant_id wieder überschrieben mit dem 
			 * konvertierten, korrekten Wert.
			 */
			entry.ds_mandant_id_ex = ""+entry.ds_mandant_id_ex;
		}
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/${url}/edit`, entry, options).toPromise());
		});
	}

	getAllEntries(url: string): Promise<Base[]> {
		return new Promise<Base[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/${url}/`, options).toPromise());
		});
	}

	getFilteredEntries(url: string, filters: any): Promise<Base[]> {
		return new Promise<Base[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/${url}/Filter`, filters, options).toPromise());
		});
	}

	getEntry(url: string, id: number): Promise<Base> {
		return new Promise<Base>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/${url}/details/` + id, options).toPromise());
		});
	}

	// #region tbl_fahrzeug
	createVehicle(vehicle: tbl_fahrzeug) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeug/create`, vehicle, options).toPromise());
		});
	}

	deleteVehicle(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeug/delete/` + id, options).toPromise());
		});
	}

	getAllVehicles() {
		return new Promise<tbl_fahrzeug[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeug`, options).toPromise());
		});
	}

	getAllVehiclesIncludingInactive() {
		return new Promise<tbl_fahrzeug[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeug/IndexIncludingInactive`, options).toPromise());
		});
	}

	getAllVehiclesIncludingServiceData() {
		return new Promise<tbl_fahrzeug[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeug/IndexIncludingServiceData`, options).toPromise());
		});
	}

	getVehicle(id: number) {
		return new Promise<tbl_fahrzeug>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeug/details/` + id, options).toPromise());
		});
	}

	getVehicleServiceData(id: number) {
		return new Promise<tbl_fahrzeug>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeug/DetailsIncludingServiceData/` + id, options).toPromise());
		});
	}

	getVehicleRemoteMaintenancePort(id: number) {
		return new Promise<tbl_fahrzeug>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeug/PostRemoteControlRequest/` + id, {}, options).toPromise());
		});
	}

	setVehicleReceiveMessagesFlag(id: number) {
		return new Promise<tbl_fahrzeug>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeug/PostSetMessagesFlag/` + id, {}, options).toPromise());
		});
	}

	setVehicleActiveState(id: number, active: boolean) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeug/setDatensatzActiveState`, { ds_this_id: id, active_state: active }, options).toPromise());
		});
	}

	getAutoGenerateLogboxId() {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeug/AutoGenerateLogboxId/`, options).toPromise());
		});
	}

	checkPersistenceVehicle(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrzeug/CheckPersistence`, date, options).toPromise());
		});
	}

	getVehicleComponentHistoryByComponent(component_id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrzeugAkteKomponenteLink/LinkedComponentsByComponentId/` + component_id, options).toPromise());
		});
	}

	getVehicleComponentHistoryByVehicle(vehicle_id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrzeugAkteKomponenteLink/LinkedComponentsByVehicleId/` + vehicle_id, options).toPromise());
		});
	}

	addVehicleComponent(data: any) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrzeugAkteKomponenteLink/AddComponentVehicleLink`, data, options).toPromise());
		});
	}

	addVehicleComponents(data: any) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrzeugAkteKomponenteLink/AddComponentVehicleLinksBatch`, data, options).toPromise());
		});
	}

	createComponents(components: Base[]) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblKomponente/CreateEntries`, components, options).toPromise());
		});
	}

	deleteComponent(component_id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblKomponente/Delete/` + component_id, options).toPromise());
		});
	}

	deleteComponentVehicleLinkEx(p_komponente_id: number, p_fahrzeug_akte_id: number) {
		return this.deleteComponentVehicleLink({fahrzeug_akte_id: p_fahrzeug_akte_id, komponente_id: p_komponente_id});
	}

	deleteComponentVehicleLink(params: any) {
		// INPUT:
		//    params["fahrzeug_akte_id"];
		//    params["komponente_id"];
		// OUTPUT:
		//    res["countToDeleteInTotal"]
		//    res["countDeletions"]
		//    res["countErrors"]
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrzeugAkteKomponenteLink/DeleteComponentVehicleLink`, params, options).toPromise());
		});
	}

	getVehicleComponentTypeGroups() {
		return new Promise<any[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblKomponenteTyp/GetKomponententypGruppen`, options).toPromise());
		});
	}

	getVehicleComponentDefaultNames() {
		return new Promise<any[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblKomponente/GetKomponentenStandardBezeichner`, options).toPromise());
		});
	}

	// #endregion tbl_fahrzeug

	// #region fahrzeug abwicklung

	getOrCreateVehicleProcessingFile(vehicle_file_id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugabwicklung/getorcreate/` + vehicle_file_id, options).toPromise());
		});
	}

	getVehicleProcessTasksByFile(vehicle_processfile_id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugabwicklungabschnitt/getbyabwicklungid/` + vehicle_processfile_id, options).toPromise());
		});
	}

	getVehicleProcessDefaultTaskTypesOrdered() {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugabwicklungabschnitttyp/getdefaultabschnitttypensortiert`, options).toPromise());
		});
	}

	createProcessTaskEntries(dataset: any[]) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeugabwicklungabschnitt/createentries`, dataset, options).toPromise());
		});
	}

	deleteProcessTaskEntry(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugabwicklungabschnitt/delete/` + id, options).toPromise());
		});
	}

	getVehicleProcessScheduleDataHistoryByFile(vehicle_processfile_id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugabwicklungereignisse/getbyabwicklungid/` + vehicle_processfile_id, options).toPromise());
		});
	}

	getVehicleProcessScheduleDataHistoryByFileAndTyp(vehicle_processfile_id: number, event_type_id: number) {
		return new Promise<any>(async resolve => {
			let params = {
				abwicklung_id: vehicle_processfile_id,
				ereignis_typ_id: event_type_id,
				nur_aktuellster: false
			};
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeugabwicklungereignisse/getbyabwicklungidandtyp`, params , options).toPromise());
		});
	}

	getVehicleProcessScheduleDataLatestByFileAndTyp(vehicle_processfile_id: number, event_type_id: number) {
		return new Promise<any>(async resolve => {
			let params = {
				abwicklung_id: vehicle_processfile_id,
				ereignis_typ_id: event_type_id,
				nur_aktuellster: true
			};
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeugabwicklungereignisse/getbyabwicklungidandtyp`, params , options).toPromise());
		});
	}

	getVehicleProcessScheduleSupplyDataHistoryByFile(vehicle_processfile_id: number) {
		return this.getVehicleProcessScheduleDataHistoryByFileAndTyp(vehicle_processfile_id, 1);
	}
	getVehicleProcessScheduleDispatchDataHistoryByFile(vehicle_processfile_id: number) {
		return this.getVehicleProcessScheduleDataHistoryByFileAndTyp(vehicle_processfile_id, 2);
	}
	getVehicleProcessScheduleSupplyDataLatestByFile(vehicle_processfile_id: number) {
		return this.getVehicleProcessScheduleDataLatestByFileAndTyp(vehicle_processfile_id, 1);
	}
	getVehicleProcessScheduleDispatchDataLatestByFile(vehicle_processfile_id: number) {
		return this.getVehicleProcessScheduleDataLatestByFileAndTyp(vehicle_processfile_id, 2);
	}

	addVehicleProcessScheduleSupplyEntry(vehicle_processfile_id: number, schedule_date: Date, entry_timestamp: Date, employee_id: number, memo: string) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			let requestParams = {
				abwicklung_id: vehicle_processfile_id,
				ereignis_typ_id: 1,
				ereignis_termin: schedule_date,
				erfassung_datum: entry_timestamp,
				mitarbeiter_id: employee_id,
				bemerkung: memo
			};
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeugabwicklungereignisse/addeventschedule`, requestParams , options).toPromise());
		});
	}

	deleteVehicleProcessScheduleEntry(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugabwicklungereignisse/delete/` + id, options).toPromise());
		});
	}

	addVehicleProcessScheduleDispatchEntry(vehicle_processfile_id: number, schedule_date: Date, entry_timestamp: Date, employee_id: number, memo: string) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			let requestParams = {
				abwicklung_id: vehicle_processfile_id,
				ereignis_typ_id: 2,
				ereignis_termin: schedule_date,
				erfassung_datum: entry_timestamp,
				mitarbeiter_id: employee_id,
				bemerkung: memo
			};
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeugabwicklungereignisse/addeventschedule`, requestParams , options).toPromise());
		});
	}

	// #endregion fahrzeug abwicklung

	// #region tbl_anhang
	getAllAttachments(type: string, id: number) {
		return new Promise<any[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblanhang/${type}/${id}`, options).toPromise());
		});
	}

	getAttachment(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get(`${environment.apiUrl}/tblanhang/details/` + id,
				{
					responseType: 'blob',
					withCredentials: true,
					headers: { authorization: 'Bearer ' + localStorage.getItem('jwt') }
				}).toPromise());
		});
	}

	deleteAttachment(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblanhang/delete/` + id, options).toPromise());
		});
	}

	// #endregion tbl_anhang

	// #region tbl_fahrzeug_akte
	createVehicleFile(vehicleFile: VehicleFile) {
		return new Promise<number>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeugakte/create`, vehicleFile, options).toPromise());
		});
	}

	deleteVehicleFile(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugakte/delete/` + id, options).toPromise());
		});
	}

	getVehicleFile(id: number) {
		return new Promise<VehicleFile>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugakte/details/` + id, options).toPromise());
		});
	}
	// #endregion tbl_fahrzeug_akte

	// #region tbl_mitarbeiter
	setStaffActiveState(id: number, active: boolean) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblMitarbeiter/setDatensatzActiveState`, { ds_this_id: id, active_state: active }, options).toPromise());
		});
	}

	getAllStaffIncludingInactive() {
		return new Promise<Base[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblMitarbeiter/IndexIncludingInactive`, options).toPromise());
		});
	}

	getStaff() {
		return new Promise<StaffMember[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblMitarbeiter`, options).toPromise());
		});
	}
	// #endregion tbl_mitarbeiter

	// #region Tour

	// #region tbl_tour
	getAllTours() {
		return new Promise<Tour[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblTour`, options).toPromise());
		});
	}

	getAllToursIncludingInactive() {
		return new Promise<Base[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblTour/IndexIncludingInactive`, options).toPromise());
		});
	}

	setTourActiveState(id: number, active: boolean) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblTour/setDatensatzActiveState`, { ds_this_id: id, active_state: active }, options).toPromise());
		});
	}
	// #endregion tbl_tour
	// #endregion Tour

	// #region tbl_abfallart
	getAllFractions() {
		return new Promise<Fraction[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblabfallart`, options).toPromise());
		});
	}

	getAllFractionsIncludingInactive() {
		return new Promise<Base[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblabfallart/IndexIncludingInactive`, options).toPromise());
		});
	}

	setFractionActiveState(id: number, active: boolean) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblabfallart/setDatensatzActiveState`, { ds_this_id: id, active_state: active }, options).toPromise());
		});
	}
	// #endregion tbl_abfallart

	// #region tbl_aufbau
	getAllConstructions() {
		return new Promise<Construction[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblAufbau`, options).toPromise());
		});
	}

	getConstruction(id: number) {
		return new Promise<Construction>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblAufbau/details/` + id, options).toPromise());
		});
	}

	createConstruction(construction: Construction) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblAufbau/create/`, construction, options).toPromise());
		});
	}
	// #endregion tbl_aufbau

	// #region tbl_auftrag
	setTaskStatus(tbl_auftrag_id: number, tbl_auftrag_status_id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblAuftrag/SetAuftragStatus`, {auftrag_id: tbl_auftrag_id, status_id: tbl_auftrag_status_id}, options).toPromise());
		});
	}
	// #endregion tbl_auftrag

	// #region tbl_lifter
	getAllLifter() {
		return new Promise<Lifter[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblLifter`, options).toPromise());
		});
	}

	getLifter(id: number) {
		return new Promise<Lifter>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblLifter/details/` + id, options).toPromise());
		});
	}

	createLifter(lifter: Lifter) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblLifter/create/`, lifter, options).toPromise());
		});
	}
	// #endregion tbl_lifter

	// #region tbl_fahrgestell
	getAllChassis() {
		return new Promise<Chassis[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrgestell`, options).toPromise());
		});
	}

	getChassis(id: number) {
		return new Promise<Chassis>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrgestell/details/` + id, options).toPromise());
		});
	}

	createChassis(chassis: Chassis) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrgestell/create/`, chassis, options).toPromise());
		});
	}
	// #endregion tbl_fahrgestell

	// #region tbl_gebiet
	getAllAreas() {
		return new Promise<Area[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGebiet`, options).toPromise());
		});
	}

	getArea(id: number) {
		return new Promise<AreaPoint[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGebiet/details/` + id, options).toPromise());
		});
	}

	createArea(area: Area) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGebiet/create/`, area, options).toPromise());
		});
	}

	deleteArea(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGebiet/delete/` + id, options).toPromise());
		});
	}

	editArea(area: Area) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGebiet/edit/`, area, options).toPromise());
		});
	}

	getAreasSurroundingPoint(point: number[]) {
		const body = { Point: point };
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGebiet/GetAreasSurroundingPoint`, body, options).toPromise());
		});
	}

	pointInPolygon(point: number[], polygonPoints) {
		const body = { Point: point, PolygonPoints: polygonPoints };
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGebiet/pointinpolygon`, body, options).toPromise());
		});
	}

	checkPersistenceArea(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGebiet/CheckPersistence`, date, options).toPromise());
		});
	}
	// #endregion tbl_gebiet

	// #region tbl_leerung_barcode_status
	getAllStatusBarcodes() {
		return new Promise<tbl_leerung_barcode_status[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblLeerungBarcodeStatus`, options).toPromise());
		});
	}

	getPossibleIds() {
		return new Promise<any[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblLeerungBarcodeStatus/possibleIds`, options).toPromise());
		});
	}

	createStatusBarcode(statusBarcode) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblLeerungBarcodeStatus/create/`, statusBarcode, options).toPromise());
		});
	}
	// #endregion tbl_leerung_barcode_status

	// #region tbl_volumen
	getAllVolumes() {
		return new Promise<tbl_volumen[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblVolumen`, options).toPromise());
		});
	}

	getAllVolumesIncludingInactive() {
		return new Promise<Base[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblVolumen/IndexIncludingInactive`, options).toPromise());
		});
	}

	setVolumeActiveState(id: number, active: boolean) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblVolumen/setDatensatzActiveState`, { ds_this_id: id, active_state: active }, options).toPromise());
		});
	}
	// #endregion tbl_volumen

	// #region tbl_ort
	getPlacesFromPostalCode(postal_code: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblOrt/GetPlacesFromPostalCode/` + postal_code, options).toPromise());
		});
	}
	// #endregion tbl_ort

	// #region tbl_strasse
	generateStreetsForVillage(ort_id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblStrasse/GenerateStreetsForVillage/` + ort_id, options).toPromise());
		});
	}
	// #endregion tbl_strasse

	// #region tbl_standort
	getAllPOI() {
		return new Promise<tbl_standort[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblStandort`, options).toPromise());
		});
	}
	// #endregion tbl_standort
	// #endregion Stammdaten

	// #region Rückfahrkartaster
	// #region tbl_rueckfahrkataster_aufzeichnung
	getTrack(id: number) {
		return new Promise<Track>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/details/` + id, options).toPromise());
		});
	}

	createTrack(track: Track) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/create/`, track, options).toPromise());
		});
	}

	deleteTrack(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/delete/` + id, options).toPromise());
		});
	}

	groupTracks() {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/GroupTracks`, options).toPromise());
		});
	}

	getTrackfileCandidates(record: any) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/GetRKfilesForRecord`, record, options).toPromise());
		});
	}

	getTrackfileCandidatesIncludingAlwaysCandidates(record: any) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/GetRKfilesForRecordIncludingAlwaysCandidates`, record, options).toPromise());
		});
	}

	getTrackfileAlwaysCandidatesOnly() {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/GetRKfilesAlwaysCandidates`, options).toPromise());
		});
	}

	createFileForRecord(record: any) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/CreateFileAndTrackForRecord`, record, options).toPromise());
		});
	}

	linkRecordToFile(recordId: number, fileId: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/LinkRecordToFile`, {pRecordId: recordId, pFileId: fileId}, options).toPromise());
		});
	}

	transferFilesToDbInTracks(limit: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/TransferFilesToDb/` + limit, options).toPromise());
		});
	}

	createFileAndTrack(record: Track) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/createFileAndTrack/`, record, options).toPromise());
		});
	}

	linkAreaToRecordAndFile(recordId: number, areaId: number, forceAreaIdOverrideInFile: boolean) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/LinkAreaToRecordAndFile`, {pRecordId: recordId, pAreaId: areaId, pForceAreaIdOverrideInFile: forceAreaIdOverrideInFile}, options).toPromise());
		});
	}
	// #endregion tbl_rueckfahrkataster_aufzeichnung
	// #endregion Rückfahrkartaster

	// #region BHV
	// #region tbl_gefaess
	getGarbageCan(id: number) {
		return new Promise<tbl_gefaess>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGefaess/details/` + id, options).toPromise());
		});
	}

	getGarbageCanByExactAnkey(ankey: string) {
		return new Promise<tbl_gefaess>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGefaess/DetailsByExactAnkey/` + ankey, options).toPromise());
		});
	}

	getGarbageCansByScheduledPickup(type_skey: string, object_skey: string, dateTime: number) {
		return new Promise<tbl_gefaess[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaess/GefaesseByAbholauftrag/`, {gefaess_1_typ_skey: type_skey, objekt_skey: object_skey, jd_geplant: dateTime}, options).toPromise());
		});
	}

	getGarbageCanHistoryByObject(id: number) {
		return new Promise<tbl_gefaess[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGefaess/GefaessHistorieObjekt/` + id, options).toPromise());
		});
	}

	moveGarbageCans(targets: any[]) {
		return new Promise<any[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjektGefaessLink/GefaesseStellen`, targets, options).toPromise());
		});
	}

	moveGarbageCan(target) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjektGefaessLink/GefaessStellen`, target, options).toPromise());
		});
	}

	moveGarbageCansFromObjectToObject(target) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjektGefaessLink/GefaesseVonStellenNach`, target, options).toPromise());
		});
	}

	printCounterIncrement(ids: number[]) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaess/PrintCounterIncrement`, ids, options).toPromise());
		});
	}

	printCounterDecrement(ids: number[]) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaess/PrintCounterDecrement`, ids, options).toPromise());
		});
	}

	printGefaess(id: number, gfDataZeitpunkt: Date = null) {
		return this.printGefaesse([id], gfDataZeitpunkt);
	}

	printGefaesse(ids: number[], gfDataZeitpunkt: Date = null) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			let params = {
				idlist: ids,
				dtZeitpunkt: gfDataZeitpunkt
			};
			resolve(this.http.post(`${environment.apiUrl}/TblGefaess/PrintGefaesse`, params, {
				responseType: 'blob',
				withCredentials: true,
				headers: { authorization: 'Bearer ' + localStorage.getItem('jwt') }
			}).toPromise());
		});
	}
	
	printGefaesseMitZeitpunkt(idsDatesParams: any[]) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post(`${environment.apiUrl}/TblGefaess/PrintGefaesseMitZeitpunkt`, idsDatesParams, {
				responseType: 'blob',
				withCredentials: true,
				headers: { authorization: 'Bearer ' + localStorage.getItem('jwt') }
			}).toPromise());
		});
	}
	// #endregion tbl_gefaess

	// #region tbl_gefaess_sperre
	exportWastebinLocks() {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get(`${environment.apiUrl}/TblGefaessSperre/DownloadAsTxtFile`, {
				responseType: 'blob',
				withCredentials: true,
				headers: { authorization: 'Bearer ' + localStorage.getItem('jwt') }
			}).toPromise());
		});
	}

	/*
	* {	
	*		"barcode" : string			// der zu sperrende barcode -> darf nicht null sein!
	*		"firstDay" : datetime		// erster Tag an dem die Sperre aktiv ist (null = today)
	*		"lastDay" : datetime		// letzter Tag an dem die Sperre aktiv ist (oder null)
	*		"lockingReason" : string	// ggf. beschreibung warum der barcode gesperrt wurde
	*		"flags" : number			// bitfeld
	*									// bit 0 = 1	:	sperre aktiv
	*									// bit 0 = 2	:	sperre wegen leerung -> firstDay ist dann der Leerungstag
	* }
	*/
	createLockBarcode(entry) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaessSperre/LockBarcode/`, entry, options).toPromise());
		});
	}
	
	createLockBarcodes(barcodeList: string[], pLockingReason: string, pFirstDay: Date, pLastDay: Date, pFlags: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			let params = {
				barcodes: barcodeList,
				lockingReason: pLockingReason,
				firstDay: pFirstDay,
				lastDay: pLastDay,
				flags: pFlags
			}
			resolve(this.http.post(`${environment.apiUrl}/TblGefaessSperre/LockBarcodes/`, params, {
				//responseType: 'blob',
				withCredentials: true,
				headers: { authorization: 'Bearer ' + localStorage.getItem('jwt') }
			}).toPromise());
		});
	}
	
	unlockBarcodes(barcodeList: string[], pLockingReasonAddition: string, pUnlockDay: Date) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			let params = {
				barcodes: barcodeList,
				unlockDay: pUnlockDay,
				lockingReasonAddition: pLockingReasonAddition 
			}
			resolve(this.http.post(`${environment.apiUrl}/TblGefaessSperre/UnlockBarcodes/`, params, {
				//responseType: 'blob',
				withCredentials: true,
				headers: { authorization: 'Bearer ' + localStorage.getItem('jwt') }
			}).toPromise());
		});
	}
	// #endregion tbl_gefaess_sperre
	// #endregion Gefäß

	// #region Firma
	// #region tbl_firma
	getAllCompanies() {
		return new Promise<tbl_firma[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFirma`, options).toPromise());
		});
	}
	// #endregion tbl_firma
	// #endregion Firma

	// #region Objekt
	// #region tbl_objekt
	getAllObjects() {
		return new Promise<tbl_objekt[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjekt`, options).toPromise());
		});
	}

	getObject(id: number) {
		return new Promise<tbl_objekt>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjekt/details/` + id, options).toPromise());
		});
	}

	getObjectByExactAnkey(ankey: string) {
		return new Promise<tbl_objekt>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjekt/DetailsByExactAnkey/` + ankey, options).toPromise());
		});
	}

	getSpecialObject(objectType: number) {
		return new Promise<tbl_objekt>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjekt/DetailsByObjectType/` + objectType, options).toPromise());
		});
	}

	getSpecialObjectId(objectType: number) {
		return new Promise<number>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjekt/IdByObjectType/` + objectType, options).toPromise());
		});
	}

	getObjectHistoryByGarbageCan(id: number) {
		return new Promise<tbl_objekt[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjekt/ObjektHistorieGefaess/` + id, options).toPromise());
		});
	}

	setCustomerObjectRelation(data) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblKundeObjektLink/ObjektStellen`, data, options).toPromise());
		});
	}
	// #endregion tbl_objekt
	// #endregion Objekt

	// #region tbl_kunde
	getClientByExactAnkey(ankey: string) {
		return new Promise<Client>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblKunde/DetailsByExactAnkey/` + ankey, options).toPromise());
		});
	}
	// #endregion tbl_kunde
	// #endregion BHV

	// #region tbl_gps
	getFilteredGps(filters) {
		return new Promise<tbl_gps[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGps/Filter`, filters, options).toPromise());
		});
	}
	// #endregion tbl_gps

	// #region tbl_leerung
	getFilteredCollections(filters) {
		return new Promise<Collection[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblLeerung/Filter`, filters, options).toPromise());
		});
	}

	getHeader() {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get(`${environment.apiUrl}/TblLeerung/GetHeader`,
				{
					responseType: 'blob',
					withCredentials: true,
					headers: { authorization: 'Bearer ' + localStorage.getItem('jwt') }
				}).toPromise());
		});
	}
	// #endregion tbl_leerung

	// #region tbl_fahrtbericht
	getFilteredTripReports(filters) {
		return new Promise<tbl_fahrtbericht[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrtbericht/Filter`, filters, options).toPromise());
		});
	}

	getAllTripReports() {
		return new Promise<tbl_fahrtbericht[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrtbericht` + (this.getMaxEntries() ? '/' + this.getMaxEntries() : ''), options).toPromise());
		});
	}

	getTripReport(id: number) {
		return new Promise<tbl_fahrtbericht>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrtbericht/details/` + id, options).toPromise());
		});
	}

	deleteTripReport(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrtbericht/Delete/` + id, options).toPromise());
		});
	}

	createTripReport(entry: tbl_fahrtbericht) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrtbericht/Create`, entry, options).toPromise());
		});
	}

	editTripReport(entry: tbl_fahrtbericht, sections: tbl_fahrtbericht_abschnitt[]) {
		if( entry && entry.ds_mandant_id_ex ) {
			/*
			 * Erläuterung:
			 * ds_mandant_id ist ein UInt64, wird beim deserialisieren von Angular leider auf 53-Bit precision beschnitten,
			 * und dann beim Editieren verfälscht zurückgeschrieben.
			 * ds_mandant_id_ex ist ein Getter im C# Modell, welches den Wert von ds_mandant_id als string liefert, somit unverfälscht, aber in falschem Format.
			 * Aber, der Trick: ds_mandant_id_ex hat ebenfalls einen Setter im C# Modell, welcher dann einen Stringwert nimmt und nach UInt64 konvertiert, diesen dann 
			 * in ds_mandant_id schreibt. Und da dieser Setter einen String bekommt, der beim serialisieren/deserialisieren erhalten bleibt, und dann auf Serverseite die 
			 * Konvertierung nach UInt64 vornimmt, wird über den String der korrekte Wert ins Frontend getragen,
			 * und wieder über den String der korrekte Wert vom Frontend in das Backend übertragen und der verfälschte UInt64 aus ds_mandant_id wieder überschrieben mit dem 
			 * konvertierten, korrekten Wert.
			 */
			entry.ds_mandant_id_ex = ""+entry.ds_mandant_id_ex;
		}
		if(sections && sections.length > 0) {
			sections.forEach(sectionRow => {
				if(sectionRow && sectionRow.ds_mandant_id_ex) {
					sectionRow.ds_mandant_id_ex = ""+sectionRow.ds_mandant_id_ex;
				}
			});
		}
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrtbericht/Edit`, { fahrtbericht: entry, abschnitte: sections }, options).toPromise());
		});
	}
	// #endregion tbl_fahrtbericht

	// #region tbl_fahrtbericht_abschhnitt
	getFilteredTripReportSections(id: number) {
		return new Promise<tbl_fahrtbericht[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrtberichtAbschnitt/Filter/`, id, options).toPromise());
		});
	}

	deleteTripReportSections(ids: number[]) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrtberichtAbschnitt/Delete`, ids, options).toPromise());
		});
	}
	// #endregion tbl_fahrtbericht_abschhnitt

	// #region tbl_fahrtbericht_typ
	getAllTripReportTypes() {
		return new Promise<tbl_fahrtbericht[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrtberichtTyp` + (this.getMaxEntries() ? '/' + this.getMaxEntries() : ''), options).toPromise());
		});
	}
	// #endregion tbl_fahrtbericht_typ

	// #region tbl_fahrtbericht_abschhnitt_typ
	getAllTripReportSectionTypes() {
		return new Promise<tbl_fahrtbericht_abschnitt_typ[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrtberichtAbschnittTyp` + (this.getMaxEntries() ? '/' + this.getMaxEntries() : ''), options).toPromise());
		});
	}
	// #endregion tbl_fahrtbericht_abschhnitt_typ
	// #endregion Berichte

	// #region Online
	getOnlineCollections(filters) {
		return new Promise<Collection[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblOnline/DataExport`, filters, options).toPromise());
		});
	}
	// #endregion Online

	// #region ESW

	getGfStammdaten(p_strObjektnummer: string, p_strKundennummer: string, p_strGefaessnummer: string, p_strKennung: string, p_nYear: number) {
		if (p_strKundennummer && p_strKundennummer.length > 0)
			return new Promise<GfStammdaten>(async resolve => {
				const options = await this.accountService.getOptions();
				resolve(this.http.get<any>(`${environment.apiUrl}/ReportsData/GefaessStammdatenblattKunde/${p_strKundennummer}/${p_strGefaessnummer}/${p_strKennung}/${p_nYear}`, options).toPromise());
				// http://localhost:57368/ReportsData/GefaessStammdatenblatKundet?p_strKundennummer=113336&p_strGefaessnummer=31000002&p_strKennung=ESW&p_nYear=2021
			});
		else
			return new Promise<GfStammdaten>(async resolve => {
				const options = await this.accountService.getOptions();
				resolve(this.http.get<any>(`${environment.apiUrl}/ReportsData/GefaessStammdatenblattObjekt/${p_strObjektnummer}/${p_strGefaessnummer}/${p_strKennung}/${p_nYear}`, options).toPromise());
				// http://localhost:57368/ReportsData/GefaessStammdatenblattObjekt?p_strObjektnummer=113336.200.1&p_strGefaessnummer=31000002&p_strKennung=ESW&p_nYear=2021
			});
	}

	getGfStammdatenPDF(pData: GfStammdaten) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post(`${environment.apiUrl}/ReportsData/GefaessStammdatenblattPdfExport`, pData,
				{
					responseType: 'blob'
				}).toPromise());
		});
		//return this.http.post<any>(`${environment.apiUrl}/ReportsData/GefaessStammdatenblattPdfExport`, pData);
	}

	checkBerechtigungBaumschnittESW(p_strKundennummer: string, p_strGefaessnummer: string, p_strKennung: string) {
		return new Promise<BaumschnittBerechtigung>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/ReportsData/CheckBerechtigungBaumschnittESW/${p_strKundennummer}/${p_strGefaessnummer}/${p_strKennung}`, options).toPromise());
		});
	}

	getBaumschnittberechtigungsscheinPDF(pData: BaumschnittBerechtigung) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post(`${environment.apiUrl}/ReportsData/BaumschnittberechtigungsscheinPdfExport`, pData,
				{
					responseType: 'blob'
				}).toPromise());
		});
	}
	// #endregion ESW
}
